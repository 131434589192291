:root {
  /* vibe_modified*/
  --primary-color: #00b4bc;
  --secondary-color: #5d7d84;
  --leftEarSide-color: #6691d4;
  --rightEarSide-color: #c97d87;
  --success-color: #66d4aa;
  --error-color: #c97d87;
  --info-icon-color: #5d7d84;
  --success-icon-color: #8cb369;
  --text-color-dark: #1a272e;
  --text-color-medium: #b6afaf;
  --text-color-light: #fff;
  --primary-ultralight-color: #e0e6ea; 
  --background-desktop: url("./Assets/Images/background_desktop.png");
  --background: url("./Assets/Images/background.png");  
}


body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color-dark);
  line-height: 1.39;
  font-size: 1.15rem;
  right: 0;
  left: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {font-family: "Comfortaa", "Roboto", sans-serif;}

.arabic {
  direction: rtl;
}

button {
  font-family: "Comfortaa", "Roboto", sans-serif;
}

.progressBar{
  margin-top: 25px;
  margin-bottom:25px;
    color: var(--text-color-medium)!important;
  font-size: 65%;
}

.progressBarContainer{

    display: flex;
    justify-content: center;
}

.progressBarItem{
  margin: 5px;
  border-radius: 20px;
  height: 0.4rem;
  width: 10%;
}

.progressBarItemInactive{
  background: var(--primary-ultralight-color);
}

.progressBarItemActive{
  background: var(--info-icon-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  outline: none !important;
  border-radius: 20px;
}

button:enabled {
  background-color: var(--primary-color);
  color: var(--text-color-light);
}

button:disabled {
  background-color: #cccccc !important;
  color: #666666;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.feedbackEmoji {
  font-size: 250%;
  text-align: center;
  color: var(--primary-color);
}

.feedbackHeader {
  padding-left: 10px;
}
.feedbackButton {
  vertical-align: middle;
  border-radius: 0px 0px 10px 10px;
  background-color: var(--info-icon-color) !important;
  border: 0px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 80%;
}

.feedbackButtonSmiley {
  height: 22px;
  margin-right: 5px;
  position:relative; 
  top:50%;
  transform: translateY(-10%);
}

.socialmediabox{
  background: var(--primary-ultralight-color);
  border: solid 1px var(--primary-color);
  border-radius: 4pt;
  margin-bottom: 10px;
  width: 100%;
  display: block;
  color: var(--primary-color);
  font-size: 70%;
}

.socialmediabox:hover{
  color: var(--secondary-color);
  text-decoration: none;
}

.socialmediaicon{
  font-size: 50pt!important;
  color: var(--primary-color);
}

.selecteSmiley {
  color: var(--info-icon-color) !important;
  font-weight: bold !important;
}

.unselectedSmiley {
  color: var(--primary-color) !important;
}

.appStoreButtonContainer {
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  max-width: 400px;
}

.vibeAppIcon {
  width: 80px;
}

.closeButton {
  float: right;
  cursor: pointer;
}
.smallButton {
  border: none;
  width: 30%;
  height: 30px;
  margin: 5% 5%;
}

.smallButtonPopup {
  border: none;
  width: 30%;
  height: 30px;
  margin: 1% 5%;
}

.largeButton {
  height: 35px;
  width: 50%;
  bottom: 0;
  left: 0;
  position: relative;
  border: none;
  margin: 10% 0 10% 0;
}

.textArea {
  text-align: center;
  border: 1px solid var(--primary-color) !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* font-size: 100%;
  width: 100%; */
}
.largeButtonPopup {
  border-radius: 20px;
  border: none;
  width: 30%;
  height: 30px;
  margin: 1% 5%;
}

.audioPlayer {
  display: none;
}

.logo {
  width: 25mm;
  height: auto;
  text-align: right;
  margin: 0px 15px;
}

.logoContainer {
  text-align: right;
}

.title {
  font-size: 7vw;
  overflow: hidden;
  width: fit-content;
  /* QUICK FIX TO MAKE TESTS WORK */
}

.backup {
  position: absolute;
  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url("./Assets/Images/background.png") center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 90%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: -1;
  top: 0;
}

.backup-light {
  position: absolute;
  background: linear-gradient(
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.85)
    ),
    url("./Assets/Images/background.png") center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 90%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: -1;
}

.pos {
  margin: 5%;
  position: absolute;
  right: 0;
  left: 0;
}

.startButton {
  height: 35px;
  width: 50%;
  bottom: 2%;
  left: 25%;
  position: fixed;
  border: none;
}

.startcont {
  text-align: center;
}

.iconStyle-1 {
  color: var(--text-color-light);
  font-size: 45px;
  border-radius: 50%;
  border: 1.8px solid var(--primary-color);
  color: var(--primary-color);
  padding: 10px;
}

.iconStyle-2 {
  color: var(--info-icon-color);
  font-size: 64px;
  margin: 10px 0px 10px 0px;
}

.iconStyle-green {
  color: var(--success-color);
  font-size: 64px;
}

.iconPopUpSmall {
  color: var(--info-icon-color);
  font-size: 200%;
}

.iconStyle-3 {
  color: var(--primary-color);
  font-size: 40px;
  padding: 5px;
}

ul {
  /* Remove default bullets */
  list-style: none;
  padding-inline-start: 16px;
  margin: 0% 5% 0% 5%;
}

ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--secondary-color);
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 10px;
  /* Also needed for space (tweak if needed) */
}

ul li {
  margin-bottom: 10px;
  list-style-position: outside;
  text-indent: -0.5em;
}

.onboardingTitle {
  vertical-align: middle;
  font-size: 28px;
}

.tt {
  display: none;
}

#txt1_SoundCheck {
  margin: 0px 30px 30px 30px;
}

#txt2_SoundCheck {
  margin: 20px 30px 0px 30px;
}

#txt1_config {
  margin: 0px 30px 0px 30px;
}

#txt_jap_market_offboarding {
  margin: 0px 20px 0px 20px;
  text-align: left;
}

.contentContainer {
  margin: 30px 30px 30px 30px;
}

.configuredCheckmark {
  font-size: 15vw;
  color: var(--success-color);
}

.btnYes {
  font-family: "Comfortaa", "Roboto", sans-serif;
  background-color: var(--primary-color) !important;
}

.btnNo {
  font-family: "Comfortaa", "Roboto", sans-serif;
  background-color: white !important;
  color: var(--text-color-dark) !important;
  border: 2px solid var(--primary-color) !important;
}

.confirm_sndChk {
  /* bottom: 2%;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0; */

  /*to avoid overlapping*/
  bottom: 0;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  margin-top: 10%;
}

.hiImage {
  width: 50%;
  max-width: 250px;
  border: 1.8px solid var(--secondary-color);
  padding: 4%;
  border-radius: 50%;
}

.modal {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px;
}

.modal-transparent > .modal-content {
  background-color: #fff0;
  color: var(--text-color-light);
  border: 0px;
}

.modal-transparent-backdrop-noanimation {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-transparent-backdrop {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-90w {
  width: 100%;
  max-width: none !important;
}

.modal-lg {
  width: 95%;
  max-width: 800px !important;
}

.outer-Overlay {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-Overlay {
  position: absolute;
  z-index: 3;
}

.txt-bold {
  font-weight: bold;
}

.leftText {
  color: var(--leftEarSide-color);
  font-weight: bold;
}

.rightText {
  color: var(--rightEarSide-color);
  font-weight: bold;
}

.text-green {
  color: var(--success-color);
}

.opacity5 {
  opacity: 0.5;
}

#leftDesktopTheme,
#rightDesktopTheme {
  display: none;
}

.popup-header {
  font-size: 110%;
  font-weight: bold;
}

#content {
  width: 100%;
}

.hiPrepImage{
  width:40%
}

.onboardingList{
  text-align: initial;
}

@media (max-height: 850) {
  .logo {
    width: 30%;
  }
}

/* destop styles */
@media screen and (min-width: 1200px) and (orientation: landscape) {
  .hiPrepImage{
    width:35%
  }
  
  body {
    font-size: 1.62rem;
  }

  .logo {
    width: 150px;
  }
  #leftDesktopTheme,
  #rightDesktopTheme {
    display: block;
  }

  .socialmediabox{
   max-width: 400px;
   display: block;
   margin-left: auto;
   margin-right: auto;
  }

  .logoContainer {
    text-align: left;
    /* margin-left: 1rem; */
  }
  .backup {
    background: url("./Assets/Images/background_desktop.png") no-repeat top
      right;
    background-size: contain;
  }
  .backup-light {
    background: linear-gradient(
        rgba(255, 255, 255, 0.8),
        rgba(170, 136, 136, 0.8)
      ),
      url("./Assets/Images/background_desktop.png") no-repeat top;
    background-size: contain;
  }
  .progressBar{
    font-size: 45%;
  }

  #icon_help {
    width: 50px;
  }
  .startButton {
    font-size: 22px;
    margin-top: 20px;
    left: 0%;
    width: 15%;
    position: relative;
    height: 40px;
  }
  #title_home {
    font-size: 4rem !important;
  }
  #txt1_SoundCheck {
    margin: 2% 10% 3% 10%;
  }
  #txt2_SoundCheck {
    margin: 2% 10% 0px 10%;
  }
  #txt1_config {
    margin: 0% 10% 0% 10%;
  }
  .iconStyle-2 {
    font-size: 72px;
    margin-bottom: 40px;
  }
  .onboardingTitle {
    vertical-align: middle;
    font-size: 300%;
  }
  #icon_warning {
    font-size: 700%;
  }
  .onboardingList {
    text-align: initial;
    display: inline-block;
    
  }
  .onboardingContainer {
    text-align: center;
  }
  .onboardingPosition {
    margin-left: 10%;
  }
  .largeButton {
    bottom: 0 !important;
    right: 0;
    left: 0;
    position: absolute;
    margin: auto;
    width: 22%;
    font-size: 22px;
    height: 40px;
  }

  .largeButtonPopup {
    width: 20%;
    height: 40px;
  }

  #configureButton.smallButton {
    margin: 5% 0% 5% 0% !important;
  }
  .smallButtonPopup {
    width: 20%;
    margin: 1%;
    height: 40px;
  }

  .smallButton {
    border: none;
    width: 17%;
    height: 40px;
    margin: 5px;
    font-size: 22px;
  }

  .confirm_sndChk {
    position: relative;
    margin-top: 5%;
  }

  #btnPlayTone {
    margin: 3%;
  }
  #btn_sndChk_popup {
    width: 25%;
  }
  .hiImage {
    width: 20%;
    padding: 2%;
    font-size: 22px;
  }
  .configuredCheckmark {
    font-size: 5vw;
    color: var(--success-color);
  }
  #content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .popup-header {
    font-size: 2.5vw;
    font-weight: bold;
  }

  .modal-content {
    font-size: 80%;
  }
  .bm-menu {
    font-size: 1.15rem;
  }

  .menuPosition {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  #txt_jap_market_offboarding {
    width: 50rem;
    margin: 0 auto;
    text-align: left;
  }
}

/* sizes similar to iphone 5 */
@media only screen and (device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .confirm_sndChk {
    bottom: 0;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: 10%;
  }
  ul li {
    font-size: 1rem;
  }
  .iconStyle-2 {
    font-size: 55px;
    margin-bottom: 10%;
    margin-top: 5%;
  }
  body {
    font-size: 1rem;
  }

}

/* mobie landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .title {
    font-size: 5.5vw;
    margin-top: 1rem !important;
    width: fit-content;
  }
  #txt1_home,
  #txt2_home,
  #txt3_home {
    margin-top: 0.5rem !important;
  }
  .startButton {
    width: 30%;
    left: 33%;
  }
  .iconStyle-2 {
    font-size: 48px;
    margin-top: 0% !important;
  }
  .largeButton {
    width: 30%;
    margin: 0% !important;
  }
  .confirm_sndChk {
    margin-top: 2%;
  }
  .smallButton {
    width: 25%;
    margin: 3% 3%;
  }
  #txt1_SoundCheck {
    margin: 20px 30px 20px 30px;
  }
}

/*standard tablets - potrait*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .confirm_sndChk {
    margin-top: 2%;
  }
  .smallButton {
    width: 15%;
    margin: 2% 2%;
  }
  #txt1_SoundCheck {
    margin: 20px 30px 20px 30px;
  }
}

.iconStyleMenu {
  color: var(--text-color-light);
  font-size: 42px;
  padding: 10px;
}

.iconLarge {
  font-size: 42px;
}

.iconSmall {
  font-size: 32px;
}

.iconStyleMenuHidden {
  color: var(--text-color-light);
  font-size: 42px;
  padding: 10px;
  visibility: hidden;
}

.lightLink {
  color: var(--text-color-medium) !important;
}

.bm-menu a {
  color: var(--text-color-light);
  font-weight: bold;
}

.bm-menu a:focus {
  border: none;
  outline: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  /* position: fixed; */
  width: 36px;
  height: 30px;
  left: 30px;
  top: 20px;
}

.bm-menu-divider {
  background-color: var(--text-color-medium);
  width: 100%;
  height: 1px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--primary-color);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--secondary-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--text-color-light);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: absolute;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  color: var(--text-color-light);
  background: var(--primary-color);
  padding: 1.5em 0.5em 0;
  font-weight: bold;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--text-color-dark);
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.selectedLoc {
  color: var(--info-icon-color) !important;
  font-weight: bold !important;
}

/* testing */
.unselectedLoc {
  color: var(--primary-color) !important;
}

.bm-menu-linkbutton {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-weight: bold;
  font-size: inherit;
  outline-style: none;
}

.bm-menu-linkbutton:hover {
  text-decoration: underline;
}

.bm-menu-linkbutton:focus {
  outline: none;
  box-shadow: none;
}

/*remove border of button when clicked*/
.bm-menu-linkbutton:active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.fixToBottom {
  position: absolute;
  bottom: 0px;
  width: 80%;
}

.copyrightfooter {
  color: var(--text-color-medium);
  font-size: 50%;
}

.legalHeader {
  font-size: 70%;
}

.legalSubHeader {
  font-size: 62%;
}

.legalContent {
  overflow-y: auto;
}

.legalText {
  font-size: 55%;
}

.legalTextParHeader {
  padding: 0px 0.5rem 0px 0px;
}

.legalTextSubParHeader {
  padding: 0px 0.5rem 0px 0.5rem;
}

#qrCodeImage {
  width: 60%;
  max-width: 250px;
}